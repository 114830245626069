import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './users';
import languageReducer from './languages';
import uiReducer from './ui';
import formTemplatesReducer from './formTemplates';
import fiscalReducer from './fiscalyear';
import paperReducer from './paper';
import wardReducer from './ward';
import templateCategoryReducer from './template';
import documentReducer from './documents';
import mayorsReducer from './mayors';
import nagarpalikaReducer from './nagarpalika';
import sifarishTypesReducer from './sifarishTypes';
import applicantsReducer from './applicants';
import designTemplateReducer from './designTemplates';

const rootReducer = combineReducers({
	users: userReducer,
	language: languageReducer,
	ui: uiReducer,
	formTemplates: formTemplatesReducer,
	fiscal: fiscalReducer,
	papers: paperReducer,
	wards: wardReducer,
	templateCategories: templateCategoryReducer,
	documents: documentReducer,
	mayors: mayorsReducer,
	nagarpalika: nagarpalikaReducer,
	sifarishTypes: sifarishTypesReducer,
	applicants: applicantsReducer,
	designTemplates: designTemplateReducer,
});

export default rootReducer;
