/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  setPapersList,
  deletePaper,
  incrementPaperCountHandler,
} from "../../_redux/slices/paper";
import PaginationComp from "../../components/Pagination";
import { FiEdit, FiTrash } from "react-icons/fi";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import { generatePreview } from "../../_helpers/methods";
import PreviewModel from "../../components/Form/PreviewModel";
import { useReactToPrint } from "react-to-print";
import InputField from "../../components/InputField/index";
import { languageSelector } from "../../_redux/slices/languages";
import { BiPrinter } from "react-icons/bi";
import NepaliDate from "nepali-date-converter";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { fetchFiscalYear } from "../../_redux/slices/fiscalyear";
import CustomTable from "../../components/Table";
import { englishToNepaliNumber as np } from "nepali-number";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

export const actionButtonStyleOverwrite = css`
  font-size: 12px;
  padding: 0.5rem 0.5rem !important;
  display: inline-grid;
  place-content: center;
`;

const Verified = ({
  setPapersList,
  deletePaper,
  incrementPaperCountHandler,
}) => {
  let papers = useSelector((state) => state.papers.values);
  let count = useSelector((state) => state.papers.count);
  const fiscalYear = useSelector((state) => state.fiscal.fiscalyear);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState([null, false]);
  const [showRenewModal, setShowRenewModal] = useState([null, false]);
  const [showPreview, setShowPreview] = useState(false);
  const [paperDetail, setPaperDetail] = useState(null);
  const [paper, setPaper] = useState(null);
  const [previewFormData, setPreviewFormData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const { language } = useSelector(languageSelector);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState([
    ["fiscal_year", null],
    ["type", null],
    ["template", null],
  ]);
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const user = useSelector((state) => state.users.user);
  const templatesCategory = useSelector(
    (state) => state?.templateCategories?.templatesCategory
  );
  const isItoAdmin = user.role === "ito_admin";

  const templates = useMemo(() => {
    let temps = [];
    if (templatesCategory?.length > 0) {
      templatesCategory?.forEach((templt) => {
        temps = [...temps, ...templt?.templates];
      });
    }
    return temps;
  }, [templatesCategory]);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(fetchFiscalYear());
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    const getPapersList = async () => {
      try {
        setIsLoading(true);
        await setPapersList(page, searchText, false, filter);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getPapersList();
  }, [page, setPapersList, searchText, filter]);

  const handlePreviewPaper = useCallback(
    async (paper) => {
      setPaperDetail(paper.values);
      setPaper(paper);
      const formData = paper.form_values;
      setPreviewFormData(formData);
      let templateWithPreviewData;
      if (paper.values?.preview) {
        templateWithPreviewData = paper.values;
      } else {
        const response = await fetchWrapper.get(`/template/${paper.template}/`);
        templateWithPreviewData = response.data;
      }
      const tempPreviewData = generatePreview(
        templateWithPreviewData,
        formData,
        user,
        {
          label: fiscalYear.find(
            (fy) => Number(fy.id) === Number(paper?.form_values?.fiscal_year)
          )?.title,
        },
        paper?.extra
      );
      setPreviewData(tempPreviewData);
      setShowPreview(true);
    },
    [fiscalYear, user]
  );

  const handleDeletePaper = () => {
    deletePaper(show[0]);
    papers.filter((val) => val.id === show.id);
    setShow([null, false]);
  };

  const handleRenewPaper = async (id) => {
    try {
      const response = await fetchWrapper.get(`/paper/${id}/renew/`);
      setShowRenewModal([null, false]);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onAfterPrint: async () => {
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
        dispatch(incrementPaperCountHandler(paper.id));
      } catch (err) {
        console.log(err, "error after printing");
      }
    },
  });

  const handlePrintPreview = (id) => {
    const body = {
      _save_and_print: true,
    };
    return fetchWrapper
      .patch(`/paper/${paper.id}/`, body)
      .then((res) => {
        setPaper((prev) => ({ ...prev, issue_id: res?.data?.issue_id }));
        handlePrint();
      })
      .catch((err) => {
        // dispatch(stopUiLoading());
        let errorMessage = err.error?.error?.non_field_error || err.message;
        errorMessage && toast.error(errorMessage[0]);
        return false;
      });
  };

  const handlePaperSearch = () => {
    setPapersList(page, searchText);
  };

  const handleTypeFilterChange = (e) => {
    const tempFilter = filter.filter((f) => f[0] !== "type");
    setFilter([...tempFilter, ["type", e.target.value]]);
  };

  const handleFYFilterChange = (e) => {
    const tempFilter = filter.filter((f) => f[0] !== "fiscal_year");
    setFilter([["fiscal_year", e.target.value], ...tempFilter]);
  };

  const handleTemplateFilterChange = (e) => {
    const tempFilter = filter.filter((f) => f[0] !== "template");
    setFilter([["template", e.target.value], ...tempFilter]);
  };

  const headers = useMemo(() => {
    if (papers?.length > 0) {
      return [
        {
          key: "applicant",
          title: "संस्था",
          headerStyleOverwrite: css`
            width: 15%;
          `,
          render: (paper) => (
            <Fragment>
              <Link to={`/applicant/${paper?.id}`}>
                {paper?.applicant_info?.name_np}
              </Link>
            </Fragment>
          ),
        },
        {
          key: "darta_fiscal",
          title: "दर्ता आ.व.",
          headerStyleOverwrite: css`
            width: 10%;
          `,
          render: (value) => np(value?.extra?.fiscal_year),
        },
        {
          key: "darta_number",
          title: "दर्ता नं",
          headerStyleOverwrite: css`
            width: 5%;
          `,
          render: (value) =>
            np(value?.form_values?.certificates_no || value?.darta_number),
        },
        {
          key: "type",
          title: "प्रमाण पत्रको प्रकार",
          headerStyleOverwrite: css`
            width: 20%;
          `,
          render: (paper) => (
            <div className="d-flex">
              <span
                css={css`
                  display: inline-flex;
                  padding: 0.2rem 0.5rem;
                  background: #6777ef;
                  color: white;
                  border-radius: 5px;
                  margin-right: 0.5rem;
                  margin-left: 0.2rem;
                  font-weight: bold;
                  align-items: center;
                  height: max-content;

                  svg {
                    margin-right: 0.1rem;
                  }
                `}
              >
                <BiPrinter fontSize="20" /> {paper.count}
              </span>
              {paper.extra.paper_title}
            </div>
          ),
        },
        {
          key: "issue_id",
          title: "इ एन नम्बर",
          headerStyleOverwrite: css`
            width: 15%;
          `,
        },
        {
          key: "issuer",
          title: "जारी मिति",
          headerStyleOverwrite: css`
            width: 10%;
          `,
          render: (paper) => (
            <>
              {/* <span
								css={css`
									display: block;
									min-height: 1.2rem;
								`}
							>
								{language === 'nepali'
									? paper?.extra.created_by_name_np || 'Guest'
									: paper?.extra.created_by_name_en || 'Guest'}
							</span> */}
              <span
                css={css`
                  font-size: 15px !important;
                `}
              >
                {language === "nepali"
                  ? new NepaliDate(new Date(paper.created_at)).format(
                      "YYYY/MM/DD",
                      "np"
                    )
                  : moment(paper.created_at).format("YYYY/MM/DD")}
              </span>
            </>
          ),
        },
        {
          key: "ward",
          title: "शाखा/ वडा",
          render: (paper) => paper?.extra?.ward,
          headerStyleOverwrite: css`
            width: 10%;
          `,
        },
        // {
        // 	key: 'expiry_date_bs',
        // 	title: 'नविकरणको मिति',
        // 	headerStyleOverwrite: css`
        // 		width: 10%;
        // 	`,
        // },
        {
          key: "actions",
          title: "कार्यहरु",
          headerStyleOverwrite: css`
            width: ${isItoAdmin ? "10%" : "8%"};
          `,
          render: (paper) => (
            <div className="w-fit d-flex ">
              <Button
                className="mr-1"
                onClick={() => handlePreviewPaper(paper)}
                css={actionButtonStyleOverwrite}
              >
                <AiFillEye />
              </Button>

              <Link
                to={`/paper-single/${paper?.id}`}
                css={css`
                  color: white;
                  :hover {
                    color: white;
                  }
                `}
              >
                <Button
                  css={actionButtonStyleOverwrite}
                  className="mr-1"
                  variant="secondary"
                >
                  <FiEdit />
                </Button>
              </Link>

              {isItoAdmin && (
                <Button
                  css={actionButtonStyleOverwrite}
                  variant="danger"
                  onClick={() => setShow([paper.id, true])}
                >
                  <FiTrash />
                </Button>
              )}
            </div>
          ),
        },
      ];
    }
    return [];
  }, [papers?.length, isItoAdmin, language, handlePreviewPaper]);

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card
            css={css`
              min-height: 600px;
              border-radius: 8px;
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
            `}
          >
            <Card.Header className="card-header-custom px-3">
              <h2>सबै जारी भएका प्रमाण पत्रहरु</h2>
              <div className="d-flex align-items-center">
                <div
                  className="mr-2"
                  css={css`
                    .filter-select {
                      height: 2.2rem;
                      width: 12vw;
                      border-radius: 2px;
                      margin-right: 14px;
                      outline: 1px solid #ccc;
                      border: 0;
                      padding: 0 0.5em;
                      border-right: 0.5em solid transparent;
                    }
                  `}
                >
                  <select
                    className="filter-select"
                    onChange={handleFYFilterChange}
                  >
                    <option value="">सबै आर्थिक वर्ष</option>
                    {fiscalYear?.map((fy) => (
                      <option key={fy.id} value={fy?.id}>
                        {fy?.title}
                      </option>
                    ))}
                  </select>
                  <select
                    className="filter-select"
                    onChange={handleTemplateFilterChange}
                  >
                    <option value="">प्रमाण पत्र प्रकार</option>
                    {templates?.length > 0 &&
                      templates?.map((template) => (
                        <option key={template?.id} value={template?.id}>
                          {template?.title}
                        </option>
                      ))}
                  </select>
                  <select
                    className="filter-select"
                    onChange={handleTypeFilterChange}
                  >
                    <option value="">सबै प्रमाण पत्र</option>
                    <option value="renewed">नविकरण भएको</option>
                    <option value="registered">नयाँ जारी गरिएको</option>
                  </select>
                </div>
                <div
                  className="card-header-form"
                  css={css`
                    min-width: 260px;
                  `}
                >
                  <div className="input-group">
                    <InputField
                      language={language}
                      className="form-control custom-search"
                      getInputData={(value) => {
                        if (value === "") {
                          return setPapersList(page);
                        }
                        setSearchText(value);
                      }}
                      placeholder={
                        language === "english"
                          ? "Please enter Organization Name"
                          : "संस्थाको नाम टाईप गर्नुहोस"
                      }
                      type="text"
                    />

                    <div className="input-group-btn">
                      <button
                        className="btn btn-primary input-search-icon"
                        onClick={handlePaperSearch}
                      >
                        <FaSearch />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="pt-0 pb-3 px-3 d-flex flex-column justify-content-between">
              <CustomTable
                headers={headers}
                data={papers?.length > 0 ? papers : []}
                isLoading={isLoading}
              />
              {count > 10 && (
                <PaginationComp
                  page={page}
                  total={count}
                  setPage={handleSetPage}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal centered show={show[1]} onHide={() => setShow([null, false])}>
        <ModalHeader className="font-weight-bold h5">
          के तपाई यो प्रमाण पत्र मेटाउन चाहानुहुन्छ?
        </ModalHeader>
        <ModalBody>
          <p>प्रमाण पत्र मेटाइएपछि पुनर्स्थापित गर्न सकिँदैन।</p>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={() => setShow([null, false])}>
            रद्द गर्नुहोस्
          </Button>

          <Button variant="outline-danger" onClick={handleDeletePaper}>
            मेटाउनुहोस्
          </Button>
        </ModalFooter>
      </Modal>
      {showRenewModal[1] && (
        <Modal
          centered
          show={showRenewModal[1]}
          onHide={() => setShowRenewModal((prev) => [null, false])}
        >
          <ModalBody>
            <p className="h5 font-weight-lighter">
              {" "}
              के तपाई यो प्रमाण पत्र नविकरण गर्न चाहानुहुन्छ?{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-success"
              onClick={() => handleRenewPaper(showRenewModal[0])}
            >
              सुनिश्चित गर्नुहोस्
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => setShowRenewModal([null, false])}
            >
              रद्द गर्नुहोस्
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {showPreview && (
        <PreviewModel
          handleClose={() => setShowPreview(false)}
          show={showPreview}
          previewData={previewData}
          previewFormData={previewFormData}
          innerRef={printRef}
          config={paperDetail.config}
          paperExtra={paper.extra}
          meta={paperDetail.meta}
          preview={true}
          handlePrint={handlePrintPreview}
          issueId={paper?.issue_id}
          documents={paper?.documents}
          issueCount={paper?.count}
          darta_number={paper?.darta_number}
          templateHeader={paper?.values?.header}
        />
      )}
    </Fragment>
  );
};

export default connect(null, {
  setPapersList,
  deletePaper,
  incrementPaperCountHandler,
})(Verified);
