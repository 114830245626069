import React, { Fragment, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { PALIKADATA } from "../_datas";
import { setSelectValue } from "../_redux/slices/formTemplates";

function SelectDistrict({ elementID, setSelectValue, setValue }) {
  const province = useSelector((state) =>
    state.formTemplates.select.find((d) => d.key === "select_province_np")
  );
  const [districts, setDistricts] = useState([]);
  useEffect(() => {
    if (province) {
      try {
        const keys = Object.keys(PALIKADATA[province?.value]);
        setDistricts(keys);
      } catch {}
    }
  }, [province]);
  return (
    <Fragment>
      <input
        name={elementID}
        id={elementID}
        type="text"
        list="provincedDistrict"
        onChange={(e) => {
          setSelectValue({ key: elementID, value: e.target.value });
          setValue(elementID, e.target.value);
        }}
      />
      <datalist id="provincedDistrict">
        {districts.map((district) => (
          <option value={district}>{district}</option>
        ))}
      </datalist>
    </Fragment>
  );
}

export default connect(null, { setSelectValue })(SelectDistrict);
