import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { PALIKADATA } from "../_datas";
import { setSelectValue } from "../_redux/slices/formTemplates";

function SelectPalika({ elementID, setSelectValue, setValue }) {
  const [palikas, setPalikas] = useState([]);
  const district = useSelector((state) =>
    state.formTemplates.select.find((d) => d.key === "select_district_np")
  )?.value;
  const province = useSelector((state) =>
    state.formTemplates.select.find((d) => d.key === "select_province_np")
  )?.value;
  useEffect(() => {
    if (district && province) {
      try {
        const keys = PALIKADATA[province][district];
        setPalikas(keys || []);
      } catch {}
    }
  }, [district, province]);

  return (
    <Fragment>
      <input
        name={elementID}
        id={elementID}
        type="text"
        list="palika"
        onChange={(e) => {
          setSelectValue({ key: elementID, value: e.target.value });
          setValue(elementID, e.target.value);
        }}
      />
      <datalist id="palika">
        {palikas.map((palika) => (
          <option value={palika["स्थानीय तहको नाम"]}>
            {palika["स्थानीय तहको नाम"]}
          </option>
        ))}
      </datalist>
    </Fragment>
  );
}

export default connect(null, { setSelectValue })(SelectPalika);
