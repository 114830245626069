import { createSlice, current } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage } from "./ui";

export const initialState = {
  values: [],
  expiredPapers: [],
  expiredPapersCount: 0,
  count: 0,
  singleValue: {},
  template: {},
  formValues: {},
  loading: true,
};

// A slice
const paperSlice = createSlice({
  name: "papers",
  initialState,
  reducers: {
    setPapers: (state, action) => {
      if (action.payload.type === "array") {
        state.values = action.payload.data;
        state.count = action.payload.count;
        state.loading = false;
      } else {
        state.singleValue = action.payload.data;
        state.formValues = action.payload.data?.form_values;
        state.template = action.payload.template;
        state.loading = false;
      }
    },
    setExpiredPapers: (state, action) => {
      if (action.payload.type === "array") {
        state.expiredPapers = action.payload.data;
        state.expiredPapersCount = action.payload.count;
        state.loading = false;
      } else {
        state.singleValue = action.payload.data;
        state.formValues = action.payload.data?.form_values;
        state.template = action.payload.template;
        state.loading = false;
      }
    },
    removePaper: (state, action) => {
      state.values = state.values.filter((val) => val.id !== action.payload);
      state.loading = false;
    },
    incrementPrintCount: (state, action) => {
      let tempValues = [...current(state).values];
      let paperIndex = tempValues.findIndex(
        (value) => value.id === action.payload
      );
      let paper = Object.assign({}, tempValues[paperIndex]);
      paper.count += 1;
      tempValues[paperIndex] = paper;
      state.values = tempValues;
    },
  },
});

const { setPapers, setExpiredPapers, incrementPrintCount, removePaper } =
  paperSlice.actions;

export const paperSelector = (state) => state.papers;

const paperReducer = paperSlice.reducer;

export default paperReducer;

export const setPapersList =
  (page, searchText, status = false, filter = []) =>
  async (dispatch) => {
    try {
      const filterQuery = filter
        ?.map((fq) => (fq[1] ? "&" + fq[0] + "=" + fq[1] : ""))
        .join("");
      const response = await fetchWrapper.get(
        `/paper/?page=${page}${status ? `&status=${status}` : ""}${
          searchText ? `&name=${searchText}` : ""
        }${filter.length > 0 ? filterQuery : ""}`
      );
      dispatch(
        setPapers({
          data: response.data.results,
          count: response.data.count,
          type: "array",
        })
      );
    } catch (err) {}
  };

export const setExpiredPapersList =
  (page, searchText, status = false, filter = []) =>
  async (dispatch) => {
    try {
      const filterQuery = filter
        ?.map((fq) => (fq[1] ? "&" + fq[0] + "=" + fq[1] : ""))
        .join("");
      const response = await fetchWrapper.get(
        `/paper/expired/?page=${page}${status ? `&status=${status}` : ""}${
          searchText ? `&name=${searchText}` : ""
        }${filter.length > 0 ? filterQuery : ""}`
      );
      dispatch(
        setExpiredPapers({
          data: response.results,
          count: response.count,
          type: "array",
        })
      );
    } catch (err) {}
  };

export const getPaper = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(`/paper/${id}/`);
    let templateRes = await fetchWrapper.get(
      `/template/${response.data.template}/`
    );
    dispatch(setPapers({ data: response.data, template: templateRes.data }));
  } catch (err) {}
};

// export const getPaper = (id) => async (dispatch) => {
//   try {
//     const response = await fetchWrapper.get(`/paper/${id}/`);
//     dispatch(setPapers({ data: response.data }));
//   } catch (err) {}
// };
export const deletePaper = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/paper/${id}/`);
    dispatch(removePaper(id));
    dispatch(setUiMessage("प्रमाण पत्र मेटियो"));
    return response;
  } catch (err) {}
};

export const incrementPaperCountHandler = (paperId) => async (dispatch) => {
  try {
    if (paperId) {
      await fetchWrapper.get(`/paper/${paperId}/increase-count/`);
      dispatch(incrementPrintCount(paperId));
    }
  } catch (err) {}
};

export const clearForm = () => async (dispatch) => {
  try {
  } catch (err) {}
};
