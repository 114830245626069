export const NEPAL_SAMBAT = {
  "2024-01-01": "११४४, थिंलागाः, ५ सोमबार",
  "2024-01-02": "११४४, थिंलागाः, ६ मंगलबार",
  "2024-01-03": "११४४, थिंलागाः, ७ बुधबार",
  "2024-01-04": "११४४, थिंलागाः, ८ बिहिबार",
  "2024-01-05": "११४४, थिंलागाः, ९ शुक्रबार",
  "2024-01-06": "११४४, थिंलागाः, १० शनिबार",
  "2024-01-07": "११४४, थिंलागाः, ११ आइतबार",
  "2024-01-08": "११४४, थिंलागाः, १२ सोमबार",
  "2024-01-09": "११४४, थिंलागाः, १३ मंगलबार",
  "2024-01-10": "११४४, थिंलागाः, १४ बुधबार",
  "2024-01-11": "११४४, थिंलागाः, १५ बिहिबार",
  "2024-01-12": "११४४, प्वहेलाथ्वः, १ शुक्रबार",
  "2024-01-13": "११४४, प्वहेलाथ्वः, २ शनिबार",
  "2024-01-14": "११४४, प्वहेलाथ्वः, ३ आइतबार",
  "2024-01-15": "११४४, प्वहेलाथ्वः, ५.९ सोमबार",
  "2024-01-16": "११४४, प्वहेलाथ्वः, ६ मंगलबार",
  "2024-01-17": "११४४, प्वहेलाथ्वः, ७ बुधबार",
  "2024-01-18": "११४४, प्वहेलाथ्वः, ८ बिहिबार",
  "2024-01-19": "११४४, प्वहेलाथ्वः, ९ शुक्रबार",
  "2024-01-20": "११४४, प्वहेलाथ्वः, १० शनिबार",
  "2024-01-21": "११४४, प्वहेलाथ्वः, ११ आइतबार",
  "2024-01-22": "११४४, प्वहेलाथ्वः, १२ सोमबार",
  "2024-01-23": "११४४, प्वहेलाथ्वः, १३ मंगलबार",
  "2024-01-24": "११४४, प्वहेलाथ्वः, १४ बुधबार",
  "2024-01-25": "११४४, प्वहेलाथ्वः, १५ बिहिबार",
  "2024-01-26": "११४४, प्वहेलागाः, १ शुक्रबार",
  "2024-01-27": "११४४, प्वहेलागाः, २ शनिबार",
  "2024-01-28": "११४४, प्वहेलागाः, ३ आइतबार",
  "2024-01-29": "११४४, प्वहेलागाः, ४ सोमबार",
  "2024-01-30": "११४४, प्वहेलागाः, ४.८ मंगलबार",
  "2024-01-31": "११४४, प्वहेलागाः, ५ बुधबार",
  "2024-02-01": "११४४, प्वहेलागाः, ६ बिहिबार",
  "2024-02-02": "११४४, प्वहेलागाः, ७ शुक्रबार",
  "2024-02-03": "११४४, प्वहेलागाः, ८ शनिबार",
  "2024-02-04": "११४४, प्वहेलागाः, ९ आइतबार",
  "2024-02-05": "११४४, प्वहेलागाः, १० सोमबार",
  "2024-02-06": "११४४, प्वहेलागाः, ११ मंगलबार",
  "2024-02-07": "११४४, प्वहेलागाः, १२ बुधबार",
  "2024-02-08": "११४४, प्वहेलागाः, १३ बिहिबार",
  "2024-02-09": "११४४, प्वहेलागाः, १४ शुक्रबार",
  "2024-02-10": "११४४, सिल्लाथ्वः, १ शनिबार",
  "2024-02-11": "११४४, सिल्लाथ्वः, २ आइतबार",
  "2024-02-12": "११४४, सिल्लाथ्वः, ३ सोमबार",
  "2024-02-13": "११४४, सिल्लाथ्वः, ४ मंगलबार",
  "2024-02-14": "११४४, सिल्लाथ्वः, ५ बुधबार",
  "2024-02-15": "११४४, सिल्लाथ्वः, ६ बिहिबार",
  "2024-02-16": "११४४, सिल्लाथ्वः, ७ शुक्रबार",
  "2024-02-17": "११४४, सिल्लाथ्वः, ८ शनिबार",
  "2024-02-18": "११४४, सिल्लाथ्वः, ९ आइतबार",
  "2024-02-19": "११४४, सिल्लाथ्वः, १० सोमबार",
  "2024-02-20": "११४४, सिल्लाथ्वः, ११ मंगलबार",
  "2024-02-21": "११४४, सिल्लाथ्वः, १२ बुधबार",
  "2024-02-22": "११४४, सिल्लाथ्वः, १३ बिहिबार",
  "2024-02-23": "११४४, सिल्लाथ्वः, १४ शुक्रबार",
  "2024-02-24": "११४४, सिल्लाथ्वः, १५ शनिबार",
  "2024-02-25": "११४४, सिल्लागाः, १ आइतबार",
  "2024-02-26": "११४४, सिल्लागाः, २ सोमबार",
  "2024-02-27": "११४४, सिल्लागाः, ३ मंगलबार",
  "2024-02-28": "११४४, सिल्लागाः, ४ बुधबार",
  "2024-02-29": "११४४, सिल्लागाः, ५ बिहिबार",
  "2024-03-01": "११४४, सिल्लागाः, ५.८ शुक्रबार",
  "2024-03-02": "११४४, सिल्लागाः, ६ शनिबार",
  "2024-03-03": "११४४, सिल्लागाः, ७ आइतबार",
  "2024-03-04": "११४४, सिल्लागाः, ८ सोमबार",
  "2024-03-05": "११४४, सिल्लागाः, ९ मंगलबार",
  "2024-03-06": "११४४, सिल्लागाः, १० बुधबार",
  "2024-03-07": "११४४, सिल्लागाः, १२.९ बिहिबार",
  "2024-03-08": "११४४, सिल्लागाः, १३ शुक्रबार",
  "2024-03-09": "११४४, सिल्लागाः, १४ शनिबार",
  "2024-03-10": "११४४, सिल्लागाः, १५ आइतबार",
  "2024-03-11": "११४४, चिल्लाथ्वः, १ सोमबार",
  "2024-03-12": "११४४, चिल्लाथ्वः, २ मंगलबार",
  "2024-03-13": "११४४, चिल्लाथ्वः, ४.९ बुधबार",
  "2024-03-14": "११४४, चिल्लाथ्वः, ५ बिहिबार",
  "2024-03-15": "११४४, चिल्लाथ्वः, ६ शुक्रबार",
  "2024-03-16": "११४४, चिल्लाथ्वः, ७ शनिबार",
  "2024-03-17": "११४४, चिल्लाथ्वः, ८ आइतबार",
  "2024-03-18": "११४४, चिल्लाथ्वः, ९ सोमबार",
  "2024-03-19": "११४४, चिल्लाथ्वः, १० मंगलबार",
  "2024-03-20": "११४४, चिल्लाथ्वः, ११ बुधबार",
  "2024-03-21": "११४४, चिल्लाथ्वः, १२ बिहिबार",
  "2024-03-22": "११४४, चिल्लाथ्वः, १३ शुक्रबार",
  "2024-03-23": "११४४, चिल्लाथ्वः, १३.८ शनिबार",
  "2024-03-24": "११४४, चिल्लाथ्वः, १४ आइतबार",
  "2024-03-25": "११४४, चिल्लाथ्वः, १५ सोमबार",
  "2024-03-26": "११४४, चिल्लागाः, १ मंगलबार",
  "2024-03-27": "११४४, चिल्लागाः, २ बुधबार",
  "2024-03-28": "११४४, चिल्लागाः, ३ बिहिबार",
  "2024-03-29": "११४४, चिल्लागाः, ४ शुक्रबार",
  "2024-03-30": "११४४, चिल्लागाः, ५ शनिबार",
  "2024-03-31": "११४४, चिल्लागाः, ६ आइतबार",
  "2024-04-01": "११४४, चिल्लागाः, ७ सोमबार",
  "2024-04-02": "११४४, चिल्लागाः, ८ मंगलबार",
  "2024-04-03": "११४४, चिल्लागाः, ९ बुधबार",
  "2024-04-04": "११४४, चिल्लागाः, १० बिहिबार",
  "2024-04-05": "११४४, चिल्लागाः, ११ शुक्रबार",
  "2024-04-06": "११४४, चिल्लागाः, १२ शनिबार",
  "2024-04-07": "११४४, चिल्लागाः, १३ आइतबार",
  "2024-04-08": "११४४, चिल्लागाः, १५.९ सोमबार",
  "2024-04-09": "११४४, चौलाथ्वः, १ मंगलबार",
  "2024-04-10": "११४४, चौलाथ्वः, २ बुधबार",
  "2024-04-11": "११४४, चौलाथ्वः, ३ बिहिबार",
  "2024-04-12": "११४४, चौलाथ्वः, ४ शुक्रबार",
  "2024-04-13": "११४४, चौलाथ्वः, ५ शनिबार",
  "2024-04-14": "११४४, चौलाथ्वः, ६ आइतबार",
  "2024-04-15": "११४४, चौलाथ्वः, ७ सोमबार",
  "2024-04-16": "११४४, चौलाथ्वः, ८ मंगलबार",
  "2024-04-17": "११४४, चौलाथ्वः, ९ बुधबार",
  "2024-04-18": "११४४, चौलाथ्वः, १० बिहिबार",
  "2024-04-19": "११४४, चौलाथ्वः, ११ शुक्रबार",
  "2024-04-20": "११४४, चौलाथ्वः, १२ शनिबार",
  "2024-04-21": "११४४, चौलाथ्वः, १३ आइतबार",
  "2024-04-22": "११४४, चौलाथ्वः, १४ सोमबार",
  "2024-04-23": "११४४, चौलाथ्वः, १५ मंगलबार",
  "2024-04-24": "११४४, चौलाथ्वः, १५.८ बुधबार",
  "2024-04-25": "११४४, चौलागाः, १ बिहिबार",
  "2024-04-26": "११४४, चौलागाः, २ शुक्रबार",
  "2024-04-27": "११४४, चौलागाः, ३ शनिबार",
  "2024-04-28": "११४४, चौलागाः, ४ आइतबार",
  "2024-04-29": "११४४, चौलागाः, ५ सोमबार",
  "2024-04-30": "११४४, चौलागाः, ६ मंगलबार",
  "2024-05-01": "११४४, चौलागाः, ७ बुधबार",
  "2024-05-02": "११४४, चौलागाः, ९.९ बिहिबार",
  "2024-05-03": "११४४, चौलागाः, १० शुक्रबार",
  "2024-05-04": "११४४, चौलागाः, ११ शनिबार",
  "2024-05-05": "११४४, चौलागाः, १२ आइतबार",
  "2024-05-06": "११४४, चौलागाः, १३ सोमबार",
  "2024-05-07": "११४४, चौलागाः, १४ मंगलबार",
  "2024-05-08": "११४४, चौलागाः, १५ बुधबार",
  "2024-05-09": "११४४, बछलाथ्वः, १ बिहिबार",
  "2024-05-10": "११४४, बछलाथ्वः, ३.९ शुक्रबार",
  "2024-05-11": "११४४, बछलाथ्वः, ४ शनिबार",
  "2024-05-12": "११४४, बछलाथ्वः, ५ आइतबार",
  "2024-05-13": "११४४, बछलाथ्वः, ६ सोमबार",
  "2024-05-14": "११४४, बछलाथ्वः, ७ मंगलबार",
  "2024-05-15": "११४४, बछलाथ्वः, ८ बुधबार",
  "2024-05-16": "११४४, बछलाथ्वः, ८.८ बिहिबार",
  "2024-05-17": "११४४, बछलाथ्वः, ९ शुक्रबार",
  "2024-05-18": "११४४, बछलाथ्वः, १० शनिबार",
  "2024-05-19": "११४४, बछलाथ्वः, ११ आइतबार",
  "2024-05-20": "११४४, बछलाथ्वः, १२ सोमबार",
  "2024-05-21": "११४४, बछलाथ्वः, १३ मंगलबार",
  "2024-05-22": "११४४, बछलाथ्वः, १४ बुधबार",
  "2024-05-23": "११४४, बछलाथ्वः, १५ बिहिबार",
  "2024-05-24": "११४४, बछलागाः, १ शुक्रबार",
  "2024-05-25": "११४४, बछलागाः, २ शनिबार",
  "2024-05-26": "११४४, बछलागाः, ३ आइतबार",
  "2024-05-27": "११४४, बछलागाः, ४ सोमबार",
  "2024-05-28": "११४४, बछलागाः, ५ मंगलबार",
  "2024-05-29": "११४४, बछलागाः, ६ बुधबार",
  "2024-05-30": "११४४, बछलागाः, ७ बिहिबार",
  "2024-05-31": "११४४, बछलागाः, ८ शुक्रबार",
  "2024-06-01": "११४४, बछलागाः, ९ शनिबार",
  "2024-06-02": "११४४, बछलागाः, १० आइतबार",
  "2024-06-03": "११४४, बछलागाः, १२.९ सोमबार",
  "2024-06-04": "११४४, बछलागाः, १३ मंगलबार",
  "2024-06-05": "११४४, बछलागाः, १४ बुधबार",
  "2024-06-06": "११४४, बछलागाः, १५ बिहिबार",
  "2024-06-07": "११४४, तछलाथ्वः, १ शुक्रबार",
  "2024-06-08": "११४४, तछलाथ्वः, २ शनिबार",
  "2024-06-09": "११४४, तछलाथ्वः, ३ आइतबार",
  "2024-06-10": "११४४, तछलाथ्वः, ४ सोमबार",
  "2024-06-11": "११४४, तछलाथ्वः, ५ मंगलबार",
  "2024-06-12": "११४४, तछलाथ्वः, ६ बुधबार",
  "2024-06-13": "११४४, तछलाथ्वः, ७ बिहिबार",
  "2024-06-14": "११४४, तछलाथ्वः, ८ शुक्रबार",
  "2024-06-15": "११४४, तछलाथ्वः, ९ शनिबार",
  "2024-06-16": "११४४, तछलाथ्वः, १० आइतबार",
  "2024-06-17": "११४४, तछलाथ्वः, ११ सोमबार",
  "2024-06-18": "११४४, तछलाथ्वः, ११.८ मंगलबार",
  "2024-06-19": "११४४, तछलाथ्वः, १२ बुधबार",
  "2024-06-20": "११४४, तछलाथ्वः, १३ बिहिबार",
  "2024-06-21": "११४४, तछलाथ्वः, १४ शुक्रबार",
  "2024-06-22": "११४४, तछलाथ्वः, १५ शनिबार",
  "2024-06-23": "११४४, तछलागाः, १ आइतबार",
  "2024-06-24": "११४४, तछलागाः, ३.९ सोमबार",
  "2024-06-25": "११४४, तछलागाः, ४ मंगलबार",
  "2024-06-26": "११४४, तछलागाः, ५ बुधबार",
  "2024-06-27": "११४४, तछलागाः, ६ बिहिबार",
  "2024-06-28": "११४४, तछलागाः, ७ शुक्रबार",
  "2024-06-29": "११४४, तछलागाः, ८ शनिबार",
  "2024-06-30": "११४४, तछलागाः, ९ आइतबार",
  "2024-07-01": "११४४, तछलागाः, १० सोमबार",
  "2024-07-02": "११४४, तछलागाः, ११ मंगलबार",
  "2024-07-03": "११४४, तछलागाः, १२ बुधबार",
  "2024-07-04": "११४४, तछलागाः, १३ बिहिबार",
  "2024-07-05": "११४४, तछलागाः, १५.९ शुक्रबार",
  "2024-07-06": "११४४, दिल्लाथ्वः, १ शनिबार",
  "2024-07-07": "११४४, दिल्लाथ्वः, २ आइतबार",
  "2024-07-08": "११४४, दिल्लाथ्वः, ३ सोमबार",
  "2024-07-09": "११४४, दिल्लाथ्वः, ३.८ मंगलबार",
  "2024-07-10": "११४४, दिल्लाथ्वः, ४ बुधबार",
  "2024-07-11": "११४४, दिल्लाथ्वः, ५ बिहिबार",
  "2024-07-12": "११४४, दिल्लाथ्वः, ६ शुक्रबार",
  "2024-07-13": "११४४, दिल्लाथ्वः, ७ शनिबार",
  "2024-07-14": "११४४, दिल्लाथ्वः, ८ आइतबार",
  "2024-07-15": "११४४, दिल्लाथ्वः, ९ सोमबार",
  "2024-07-16": "११४४, दिल्लाथ्वः, १० मंगलबार",
  "2024-07-17": "११४४, दिल्लाथ्वः, ११ बुधबार",
  "2024-07-18": "११४४, दिल्लाथ्वः, १२ बिहिबार",
  "2024-07-19": "११४४, दिल्लाथ्वः, १३ शुक्रबार",
  "2024-07-20": "११४४, दिल्लाथ्वः, १४ शनिबार",
  "2024-07-21": "११४४, दिल्लाथ्वः, १५ आइतबार",
  "2024-07-22": "११४४, दिल्लागाः, १ सोमबार",
  "2024-07-23": "११४४, दिल्लागाः, २ मंगलबार",
  "2024-07-24": "११४४, दिल्लागाः, ३ बुधबार",
  "2024-07-25": "११४४, दिल्लागाः, ५.९ बिहिबार",
  "2024-07-26": "११४४, दिल्लागाः, ६ शुक्रबार",
  "2024-07-27": "११४४, दिल्लागाः, ७ शनिबार",
  "2024-07-28": "११४४, दिल्लागाः, ८ आइतबार",
  "2024-07-29": "११४४, दिल्लागाः, ९ सोमबार",
  "2024-07-30": "११४४, दिल्लागाः, १० मंगलबार",
  "2024-07-31": "११४४, दिल्लागाः, ११ बुधबार",
  "2024-08-01": "११४४, दिल्लागाः, १२ बिहिबार",
  "2024-08-02": "११४४, दिल्लागाः, १३ शुक्रबार",
  "2024-08-03": "११४४, दिल्लागाः, १४ शनिबार",
  "2024-08-04": "११४४, दिल्लागाः, १५ आइतबार",
  "2024-08-05": "११४४, गुंलाथ्वः, १ सोमबार",
  "2024-08-06": "११४४, गुंलाथ्वः, २ मंगलबार",
  "2024-08-07": "११४४, गुंलाथ्वः, ३ बुधबार",
  "2024-08-08": "११४४, गुंलाथ्वः, ४ बिहिबार",
  "2024-08-09": "११४४, गुंलाथ्वः, ५ शुक्रबार",
  "2024-08-10": "११४४, गुंलाथ्वः, ६ शनिबार",
  "2024-08-11": "११४४, गुंलाथ्वः, ६.८ आइतबार",
  "2024-08-12": "११४४, गुंलाथ्वः, ७ सोमबार",
  "2024-08-13": "११४४, गुंलाथ्वः, ८ मंगलबार",
  "2024-08-14": "११४४, गुंलाथ्वः, ९ बुधबार",
  "2024-08-15": "११४४, गुंलाथ्वः, १० बिहिबार",
  "2024-08-16": "११४४, गुंलाथ्वः, ११ शुक्रबार",
  "2024-08-17": "११४४, गुंलाथ्वः, १२ शनिबार",
  "2024-08-18": "११४४, गुंलाथ्वः, १३ आइतबार",
  "2024-08-19": "११४४, गुंलाथ्वः, १५.९ सोमबार",
  "2024-08-20": "११४४, गुंलागाः, १ मंगलबार",
  "2024-08-21": "११४४, गुंलागाः, २ बुधबार",
  "2024-08-22": "११४४, गुंलागाः, ३ बिहिबार",
  "2024-08-23": "११४४, गुंलागाः, ४ शुक्रबार",
  "2024-08-24": "११४४, गुंलागाः, ५ शनिबार",
  "2024-08-25": "११४४, गुंलागाः, ६ आइतबार",
  "2024-08-26": "११४४, गुंलागाः, ८.९ सोमबार",
  "2024-08-27": "११४४, गुंलागाः, ९ मंगलबार",
  "2024-08-28": "११४४, गुंलागाः, १० बुधबार",
  "2024-08-29": "११४४, गुंलागाः, ११ बिहिबार",
  "2024-08-30": "११४४, गुंलागाः, १२ शुक्रबार",
  "2024-08-31": "११४४, गुंलागाः, १३ शनिबार",
  "2024-09-01": "११४४, गुंलागाः, १४ आइतबार",
  "2024-09-02": "११४४, गुंलागाः, १५ सोमबार",
  "2024-09-03": "११४४, गुंलागाः, १५.८ मंगलबार",
  "2024-09-04": "११४४, यंलाथ्वः, १ बुधबार",
  "2024-09-05": "११४४, यंलाथ्वः, २ बिहिबार",
  "2024-09-06": "११४४, यंलाथ्वः, ३ शुक्रबार",
  "2024-09-07": "११४४, यंलाथ्वः, ४ शनिबार",
  "2024-09-08": "११४४, यंलाथ्वः, ५ आइतबार",
  "2024-09-09": "११४४, यंलाथ्वः, ६ सोमबार",
  "2024-09-10": "११४४, यंलाथ्वः, ७ मंगलबार",
  "2024-09-11": "११४४, यंलाथ्वः, ८ बुधबार",
  "2024-09-12": "११४४, यंलाथ्वः, ९ बिहिबार",
  "2024-09-13": "११४४, यंलाथ्वः, १० शुक्रबार",
  "2024-09-14": "११४४, यंलाथ्वः, ११ शनिबार",
  "2024-09-15": "११४४, यंलाथ्वः, १२ आइतबार",
  "2024-09-16": "११४४, यंलाथ्वः, १३ सोमबार",
  "2024-09-17": "११४४, यंलाथ्वः, १४ मंगलबार",
  "2024-09-18": "११४४, यंलाथ्वः, १५ बुधबार",
  "2024-09-19": "११४४, यंलागाः, २.९ बिहिबार",
  "2024-09-20": "११४४, यंलागाः, ३ शुक्रबार",
  "2024-09-21": "११४४, यंलागाः, ४ शनिबार",
  "2024-09-22": "११४४, यंलागाः, ५ आइतबार",
  "2024-09-23": "११४४, यंलागाः, ६ सोमबार",
  "2024-09-24": "११४४, यंलागाः, ७ मंगलबार",
  "2024-09-25": "११४४, यंलागाः, ८ बुधबार",
  "2024-09-26": "११४४, यंलागाः, ९ बिहिबार",
  "2024-09-27": "११४४, यंलागाः, १० शुक्रबार",
  "2024-09-28": "११४४, यंलागाः, ११ शनिबार",
  "2024-09-29": "११४४, यंलागाः, १२ आइतबार",
  "2024-09-30": "११४४, यंलागाः, १३ सोमबार",
  "2024-10-01": "११४४, यंलागाः, १४ मंगलबार",
  "2024-10-02": "११४४, यंलागाः, १५ बुधबार",
  "2024-10-03": "११४४, कौलाथ्वः, १ बिहिबार",
  "2024-10-04": "११४४, कौलाथ्वः, २ शुक्रबार",
  "2024-10-05": "११४४, कौलाथ्वः, ३ शनिबार",
  "2024-10-06": "११४४, कौलाथ्वः, ३.८ आइतबार",
  "2024-10-07": "११४४, कौलाथ्वः, ४ सोमबार",
  "2024-10-08": "११४४, कौलाथ्वः, ५ मंगलबार",
  "2024-10-09": "११४४, कौलाथ्वः, ६ बुधबार",
  "2024-10-10": "११४४, कौलाथ्वः, ७ बिहिबार",
  "2024-10-11": "११४४, कौलाथ्वः, ८ शुक्रबार",
  "2024-10-12": "११४४, कौलाथ्वः, ९ शनिबार",
  "2024-10-13": "११४४, कौलाथ्वः, १० आइतबार",
  "2024-10-14": "११४४, कौलाथ्वः, ११ सोमबार",
  "2024-10-15": "११४४, कौलाथ्वः, १३.९ मंगलबार",
  "2024-10-16": "११४४, कौलाथ्वः, १४ बुधबार",
  "2024-10-17": "११४४, कौलाथ्वः, १५ बिहिबार",
  "2024-10-18": "११४४, कौलागाः, १ शुक्रबार",
  "2024-10-19": "११४४, कौलागाः, २ शनिबार",
  "2024-10-20": "११४४, कौलागाः, ३ आइतबार",
  "2024-10-21": "११४४, कौलागाः, ५.९ सोमबार",
  "2024-10-22": "११४४, कौलागाः, ६ मंगलबार",
  "2024-10-23": "११४४, कौलागाः, ७ बुधबार",
  "2024-10-24": "११४४, कौलागाः, ८ बिहिबार",
  "2024-10-25": "११४४, कौलागाः, ९ शुक्रबार",
  "2024-10-26": "११४४, कौलागाः, १० शनिबार",
  "2024-10-27": "११४४, कौलागाः, ११ आइतबार",
  "2024-10-28": "११४४, कौलागाः, ११.८ सोमबार",
  "2024-10-29": "११४४, कौलागाः, १२ मंगलबार",
  "2024-10-30": "११४४, कौलागाः, १३ बुधबार",
  "2024-10-31": "११४४, कौलागाः, १४ बिहिबार",
  "2024-11-01": "११४४, कौलागाः, १५ शुक्रबार",
  "2024-11-02": "११४५, कछलाथ्वः, १ शनिबार",
  "2024-11-03": "११४५, कछलाथ्वः, २ आइतबार",
  "2024-11-04": "११४५, कछलाथ्वः, ३ सोमबार",
  "2024-11-05": "११४५, कछलाथ्वः, ४ मंगलबार",
  "2024-11-06": "११४५, कछलाथ्वः, ५ बुधबार",
  "2024-11-07": "११४५, कछलाथ्वः, ६ बिहिबार",
  "2024-11-08": "११४५, कछलाथ्वः, ७ शुक्रबार",
  "2024-11-09": "११४५, कछलाथ्वः, ८ शनिबार",
  "2024-11-10": "११४५, कछलाथ्वः, ९ आइतबार",
  "2024-11-11": "११४५, कछलाथ्वः, १० सोमबार",
  "2024-11-12": "११४५, कछलाथ्वः, ११ मंगलबार",
  "2024-11-13": "११४५, कछलाथ्वः, १२ बुधबार",
  "2024-11-14": "११४५, कछलाथ्वः, १३ बिहिबार",
  "2024-11-15": "११४५, कछलाथ्वः, १४ शुक्रबार",
  "2024-11-16": "११४५, कछलागाः, १.९ शनिबार",
  "2024-11-17": "११४५, कछलागाः, २ आइतबार",
  "2024-11-18": "११४५, कछलागाः, ३ सोमबार",
  "2024-11-19": "११४५, कछलागाः, ४ मंगलबार",
  "2024-11-20": "११४५, कछलागाः, ५ बुधबार",
  "2024-11-21": "११४५, कछलागाः, ६ बिहिबार",
  "2024-11-22": "११४५, कछलागाः, ७ शुक्रबार",
  "2024-11-23": "११४५, कछलागाः, ८ शनिबार",
  "2024-11-24": "११४५, कछलागाः, ९ आइतबार",
  "2024-11-25": "११४५, कछलागाः, १० सोमबार",
  "2024-11-26": "११४५, कछलागाः, ११ मंगलबार",
  "2024-11-27": "११४५, कछलागाः, १२ बुधबार",
  "2024-11-28": "११४५, कछलागाः, १२.८ बिहिबार",
  "2024-11-29": "११४५, कछलागाः, १३ शुक्रबार",
  "2024-11-30": "११४५, कछलागाः, १४ शनिबार",
  "2024-12-01": "११४५, कछलागाः, १५ आइतबार",
  "2024-12-02": "११४५, थिंलाथ्वः, १ सोमबार",
  "2024-12-03": "११४५, थिंलाथ्वः, २ मंगलबार",
  "2024-12-04": "११४५, थिंलाथ्वः, ३ बुधबार",
  "2024-12-05": "११४५, थिंलाथ्वः, ४ बिहिबार",
  "2024-12-06": "११४५, थिंलाथ्वः, ५ शुक्रबार",
  "2024-12-07": "११४५, थिंलाथ्वः, ६ शनिबार",
  "2024-12-08": "११४५, थिंलाथ्वः, ७ आइतबार",
  "2024-12-09": "११४५, थिंलाथ्वः, ८ सोमबार",
  "2024-12-10": "११४५, थिंलाथ्वः, १०.९ मंगलबार",
  "2024-12-11": "११४५, थिंलाथ्वः, ११ बुधबार",
  "2024-12-12": "११४५, थिंलाथ्वः, १२ बिहिबार",
  "2024-12-13": "११४५, थिंलाथ्वः, १३ शुक्रबार",
  "2024-12-14": "११४५, थिंलाथ्वः, १४ शनिबार",
  "2024-12-15": "११४५, थिंलाथ्वः, १५ आइतबार",
  "2024-12-16": "११४५, थिंलागाः, १ सोमबार",
  "2024-12-17": "११४५, थिंलागाः, २ मंगलबार",
  "2024-12-18": "११४५, थिंलागाः, ३ बुधबार",
  "2024-12-19": "११४५, थिंलागाः, ४ बिहिबार",
  "2024-12-20": "११४५, थिंलागाः, ५ शुक्रबार",
  "2024-12-21": "११४५, थिंलागाः, ६ शनिबार",
  "2024-12-22": "११४५, थिंलागाः, ७ आइतबार",
  "2024-12-23": "११४५, थिंलागाः, ८ सोमबार",
  "2024-12-24": "११४५, थिंलागाः, ९ मंगलबार",
  "2024-12-25": "११४५, थिंलागाः, १० बुधबार",
  "2024-12-26": "११४५, थिंलागाः, ११ बिहिबार",
  "2024-12-27": "११४५, थिंलागाः, १२ शुक्रबार",
  "2024-12-28": "११४५, थिंलागाः, १३ शनिबार",
  "2024-12-29": "११४५, थिंलागाः, १४ आइतबार",
  "2024-12-30": "११४५, थिंलागाः, १५ सोमबार",
};
