import React, { Fragment, useEffect, useState } from "react";

import "../../components/Form/index.css";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getPaper, paperSelector } from "../../_redux/slices/paper";
import { VscLoading } from "react-icons/vsc";
import FormElements from "../../components/Form";

function PaperSingle({ getPaper }) {
  const { paperId } = useParams();
  const data = useSelector(paperSelector);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchPaper = async () => {
      try {
        setIsLoading(true);
        await getPaper(paperId);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchPaper();
  }, [getPaper, paperId]);

  let templateWithPreviewData;
  let paperExtra;
  if (data.singleValue?.values?.preview) {
    templateWithPreviewData = {
      ...data.singleValue?.values,
      values: data.template.values,
    };
    paperExtra = data.singleValue?.extra;
  } else if (data.template) {
    templateWithPreviewData = data.template;
  } else {
    templateWithPreviewData = {};
  }
  const paperDate = data.singleValue;

  return (
    <Fragment>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center align-items-center height-75vh">
          <VscLoading className="spin" fontSize="38" />
        </div>
      ) : (
        <React.Fragment>
          <FormElements
            singleValue={paperDate}
            paperExtra={paperExtra}
            data={templateWithPreviewData}
            vals={data.formValues}
          />
        </React.Fragment>
      )}
    </Fragment>
  );
}

export default connect(null, { getPaper })(PaperSingle);
